<template>
  <v-container
    fluid
    fill-height
    class="background-img"
    v-if="render"
  >
    <v-row>
      <v-col
        class="align-self-center"
        cols="6"
      >
        <v-img
          class="logo2"
          src="../assets/cot_logo2.png"
        >
        </v-img>
      </v-col>
      <v-col class="align-self-center">
        <v-card
          class="elevation-12 size-card d-flex flex-column pa-6 justify-center"
          :class="{'align-start': userIsAdmin}"
        >
          <v-card-title
            v-if="license.expired"
            class="d-flex flex-column"
          >
            <v-img
              v-if="!userIsAdmin"
              class="mb-8"
              contain
              width="60"
              src="../assets/cross-no.svg"
            />
            <div>{{$t('t.LicenseInfoExpiration', { expirationDate: formatDate(license.expirationDate) })}}</div>
          </v-card-title>
          <v-card-title
            v-else-if="!license.valid"
            class="d-flex flex-column"
          >{{$t('t.LicenseInfoError')}}</v-card-title>
          <v-card-title
            v-else
            class="d-flex flex-column"
          >{{$t('t.LicenseInfoExpireAt', { expirationDate: formatDate(license.expirationDate) })}}</v-card-title>
          <template v-if="userIsAdmin">
            <v-card-text class="pb-6">
              <div class="pt-2 pb-8">{{$t('t.PleaseInsertLicenseKey')}}</div>
              <div class="text-h6 black--text pb-4">{{$t('t.LicenseKey')}}</div>
              <v-form>
                <v-textarea
                  :placeholder="$t('t.LicenseKeySample')"
                  rows=5
                  outlined
                  hide-details
                  v-model="licenseKey"
                />
              </v-form>
            </v-card-text>
            <v-card-actions
              class="justify-center"
              style="width: 100%"
            >
              <v-btn
                color="primary"
                class="border-radius px-4"
                v-ripple
                :disabled="!licenseKey.length"
                @click="validateLicenseKey()"
              >
                {{$t('t.ValidateKey')}}
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showError"
      color="error"
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import licenseController from '@/licenseController'

export default {
  name: 'License',
  data: () => ({
    errorMessage: '',
    license: {},
    licenseKey: '',
    render: true,
    showError: false,
    timeout: 6000
  }),
  async mounted () {
    this.license = await licenseController.getLicenseInfo()
  },
  computed: {
    userIsAdmin () {
      return this.$store.getters.isAdmin()
    }
  },
  methods: {
    validateLicenseKey () {
      if (this.licenseKey.length) {
        licenseController.resetLicenseStatus()
        this.$http().post('/core/v6/settings/license', { keyToValidate: this.licenseKey })
          .then(() => {
            this.$router.replace({ path: '/login' })
          })
          .catch(e => {
            this.errorMessage = this.$t(e.response.data.message)
            this.showError = true
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.background-img
  background-image url('../assets/background-login2.png')
  background-size cover

.size-card
  max-width 680px
  min-height 425px
  background-color #fff !important

.logo2
  width 100%
  max-width 600px
  max-height 600px
  margin-left auto
  margin-right auto
  z-index 2
</style>
